$(document).ready(function() {
	var isMobile = false;
	if(window.matchMedia !== 'undefined') {
		var isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;
	}

	if(!isMobile && typeof(Headroom) == 'function') {
		// Headroom
		var header = document.getElementById('header');
		var headroom  = new Headroom(header);
		headroom.init(); 
	}


	// Sticky ability hover panel
	if(!isMobile) {

		// Abilities
		var $abilityPanel = $('#ability-popup');
		if($abilityPanel.length) {
			$('.ability')
				.mouseenter(function() {
					var data = $(this).data('ability');
					
					$abilityPanel.find('.title').text(data.name);
					$abilityPanel.find('.hero').text(data.hero_name);
					$abilityPanel.find('.slot').text(data.slot);
					$abilityPanel.find('.summary').text(data.description);

					$abilityPanel.find('img').attr('src', data.icon);

					var html = '';
					for (var i = data.stats.length - 1; i >= 0; i--) {
						var stat = data.stats[i];
						html += '<tr><td>' + 
									stat.stat_name + 
								'</td><td>' + 
									stat.level_1 + 
								'</td><td>' + 
									stat.level_2 + 
								'</td><td>' +  
									stat.level_3 + 
								'</td><td>' +  
									(stat.level_4 || '-' ) + 
								'</td><td>' +  
									(stat.level_5 || '-' ) + 
								'</td><td>' +  
									(stat.crystal_ratio ? stat.crystal_ratio + '%' : '-') + 
								'</td><td>' +  
									(stat.weapon_ratio ? stat.weapon_ratio + '%' : '-') + 
								'</td><tr>';
					};

					document.getElementById('ability-stats').style.visibility = data.stats.length ? 'visible' : 'hidden';
					$('#ability-stats tbody').html(html);

					$abilityPanel.css('visibility', 'visible');

				});
		
			if ($.fn.stick_in_parent) {
				$abilityPanel.stick_in_parent({
					offset_top: 50
				});
			}
			$('.ability').eq(0).trigger('mouseenter');
		}

		// Items
		var $itemPanel = $('#item-popup');
		if($itemPanel.length) {
			$(document)
					.on('mouseenter', '.equipment-item', function() {
						// Break the data() cache.  On the creator page this has issues when removing items
						var data = $(this).attr('data-item');
						data = JSON.parse(data);
						
						$itemPanel.find('.title').text(data.name);
						$itemPanel.find('.tier').text(data.tier);
						$itemPanel.find('.cost').text(data.cost);
						$itemPanel.find('.category').text(data.category);
						$itemPanel.find('.description').text(data.description);

						$itemPanel.find('img').attr('src', data.icon);

						var $effects = $itemPanel.find('.effects');
						
						if(data.effects) {
							var html = '';
							for (var i = data.effects.length - 1; i >= 0; i--) {
								html += '<div class="text summary">' + data.effects[i].effect_name + '</div>';
							}

							$effects.find('.effects-info').html(html);
							$effects.css('visibility', 'visible');
						} else {
							$effects.css('visibility', 'hidden');
						}

						if(data.activation_ability) {
							$itemPanel.find('.activate').css('visibility', 'visible');
							$itemPanel.find('.activate-ability').text(data.activation_ability);
							$itemPanel.find('.activate-cooldown').text(data.activation_cooldown);
						} else {
							$itemPanel.find('.activate').css('visibility', 'hidden');	
						}
						

						$itemPanel.css('visibility', 'visible');

					});
			
				if ($.fn.stick_in_parent) {
					$itemPanel.stick_in_parent({
						offset_top: 50
					});
				}
				$('.equipment-item').eq(0).trigger('mouseenter');
			}
	}

	// Table Sorting
	if ($.fn.tablesorter) {
		var sort = $('#data-table').data('sort');
		$('#data-table').tablesorter({
			sortList: [ sort ]
		});

		// If we have a data-href attribute, then linkify the table items
		if ( $('#data-table tbody tr').eq(0).data('href') ) {
			$('#data-table tbody tr').on('click', function() {
				document.location = $(this).data('href');
			});
		}
	}
	
	$('#navbar-collapse').collapse({
		toggle: false
	});

});